import {
  flexRender,
  Table as TableType,
  Cell,
  Row,
} from '@tanstack/react-table';
import { RowData } from '@tanstack/table-core';
import React from 'react';
import Table from '../Table/Table';

type RenderCellArgs<TData extends RowData> = {
  cell: Cell<TData, unknown>;
  row: Row<TData>;
  index: number;
};

type DetailRowComponent<TData extends RowData> = React.ComponentType<{
  row: Row<TData>;
}>;

export type Props<TData extends RowData> = {
  table: TableType<TData>;
  pagination?: React.ReactNode;
  actions?: React.ReactNode;
  slotProps?: {
    root?: {
      hideHeaders?: boolean;
    };
    table?: React.ComponentProps<typeof Table>;
    tableBody?: React.ComponentProps<typeof Table.Body>;
    tableRow?: (item: TData) => React.ComponentProps<typeof Table.Row>;
    tableCell?: (
      item: TData,
      index: number,
    ) => React.ComponentProps<typeof Table.Cell>;
    detailRowComponent?: DetailRowComponent<TData>;
  };
  renderCell?({ cell, row, index }: RenderCellArgs<TData>): React.ReactNode;
};

export function defaultRenderCell<TData extends RowData>({
  cell,
}: RenderCellArgs<TData>) {
  return flexRender(cell.column.columnDef.cell, cell.getContext());
}

function DefaultTable<TData extends RowData>(props: Props<TData>) {
  const {
    table,
    actions,
    pagination,
    slotProps: {
      root: { hideHeaders = false } = {},
      table: tableProps = {},
      tableBody = {},
      tableRow: tableRowProps = () => {},
      tableCell: tableCellProps = () => {},
      detailRowComponent: DetailRowComponent,
    } = {},
    renderCell = defaultRenderCell,
  } = props;

  return (
    <Table.Wrapper>
      {actions && <Table.Actions>{actions}</Table.Actions>}
      <Table {...tableProps}>
        {!hideHeaders && (
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.Cell
                    variant="head"
                    key={header.id}
                    align={header.column.columnDef.meta?.align}
                    size={header.column.columnDef.meta?.size}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
        )}
        <Table.Body {...tableBody}>
          {table.getRowModel().rows.map((row) => {
            if (row.depth > 0 && DetailRowComponent) {
              return (
                <Table.Row {...tableRowProps?.(row.original)} key={row.id}>
                  <Table.Cell colSpan={table.getAllLeafColumns().length}>
                    <DetailRowComponent row={row} />
                  </Table.Cell>
                </Table.Row>
              );
            }

            return (
              <Table.Row {...tableRowProps?.(row.original)} key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <Table.Cell
                    {...tableCellProps?.(row.original, index)}
                    key={cell.id}
                    size={cell.column.columnDef.meta?.size}
                    align={cell.column.columnDef.meta?.align}
                  >
                    {renderCell({ cell, row, index })}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
        {pagination && (
          <Table.Foot>
            <Table.Row>{pagination}</Table.Row>
          </Table.Foot>
        )}
      </Table>
    </Table.Wrapper>
  );
}

export default DefaultTable;
