import React from 'react';
import css from './divider.module.scss';
import clsx from 'clsx';

type Props = {
  variant?: 'default' | 'dashed';
};

const Divider = (props: Props) => {
  const { variant = 'default' } = props;

  return <hr className={clsx(css.root, css[`variant-${variant}`])} />;
};

export default Divider;
