import React, { forwardRef } from 'react';
import css from './navigation.module.scss';
import clsx from 'clsx';
import ActionSheet, { Action } from '../ActionSheet/ActionSheet';

const Navigation = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ children }, ref) => (
    <div className={css['navigation']} ref={ref}>
      <ul className={css.list}>{children}</ul>
    </div>
  ),
);

type NavigationItemProps = {
  title?: string;
  icon?: React.ReactElement | null;
  actions?: Action[];
  isActive?: boolean;
  disabled?: boolean;
} & React.HTMLProps<HTMLLIElement>;

const NavigationItem = forwardRef<HTMLLIElement, NavigationItemProps>(
  (
    {
      title,
      icon = null,
      actions,
      isActive,
      children,
      disabled,
      onClick,
      ...rest
    },
    ref,
  ) => (
    <li
      className={clsx(
        css['navigation-item'],
        isActive && css['is-active'],
        disabled && css['is-disabled'],
      )}
      ref={ref}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      <div className={css.top}>
        {icon && <span className={clsx(css.icon)}>{icon}</span>}
        {title && <span className={css.title}>{title}</span>}
        {actions && (
          <span className={css['action-sheet']}>
            <ActionSheet size={'small'} actions={actions} />
          </span>
        )}
      </div>
      {children && <ul className={css.children}>{children}</ul>}
    </li>
  ),
);

type ChildNavigationItemProps = {
  title?: string;
  icon?: React.ReactElement | null;
  actions?: Action[];
  isActive?: boolean;
} & React.HTMLProps<HTMLLIElement>;

const ChildNavigationItem = forwardRef<HTMLLIElement, ChildNavigationItemProps>(
  ({ title, icon = null, actions, isActive, onClick, ...rest }, ref) => (
    <li
      className={clsx(
        css['child-navigation-item'],
        isActive && css['is-active'],
      )}
      ref={ref}
      {...rest}
    >
      {title && (
        <span onClick={onClick} className={css.title}>
          {title}
        </span>
      )}
      {icon && <span className={clsx(css.icon)}>{icon}</span>}
      {actions && actions.length > 0 && (
        <span className={css['action-sheet']}>
          <ActionSheet size={'small'} actions={actions} />
        </span>
      )}
    </li>
  ),
);

export { Navigation, NavigationItem, ChildNavigationItem };
