import { useTranslation } from 'utils/translation';
import {
  ChildNavigationItem,
  Navigation,
  NavigationItem,
} from '../../components/v2/Navigation/Navigation';
import Icon from '../../components/v2/Icon/Icon';
import {
  Sidebar,
  SidebarWrapper,
  SidebarNotice,
} from '../../components/v2/Sidebar/Sidebar';
import React, { useState } from 'react';
import SidebarSearch from '../../components/v2/Sidebar/SidebarSearch';
import usePermissions from '../../modules/auth/permissions';
import { useRemoveReportWithConfirmation } from '../../modules/reports/hooks/use-remove-report';
import useReports from '../../modules/reports/hooks/use-reports';
import { Report } from '../../modules/reports/types';
import { useLocation, useNavigate } from 'utils/router';
import { UpdateReportName } from 'modules/reports/components/SaveReport';
import ReportContextProvider from 'modules/reports/context/ReportContext';
import SystemSummary from '../../modules/ui/components/SystemSummary';
import { encode } from '../../utils/state-encoder';
import { route } from 'router';
import { useAppSettings } from 'modules/ui/hooks';
import BasketButton from 'modules/basket/components/BasketButton';
import config from 'config/app';
import Spacer from 'components/v2/Spacer/Spacer';
import { applyFilters, Filters } from '../../core';
import Divider from '../../components/v2/Divider/Divider';

const ReportNavItem = ({ report }: { report: Report }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isActive = location.pathname.startsWith(`/assets/report/${report.id}`);
  const navigate = useNavigate();
  const onRemove = useRemoveReportWithConfirmation(report);
  const [editing, setEditing] = useState(false);
  const actions: any[] = [];

  if (report.isEditable) {
    actions.push({
      label: t('Edit'),
      icon: <Icon name="far fa-edit" />,
      action: () => setEditing(true),
    });
  }

  if (report.isRemovable) {
    actions.push({
      label: t('Remove'),
      icon: <Icon name="far fa-trash" />,
      action: onRemove,
    });
  }

  const onClick = () => {
    navigate(route('report.index', { id: report.id }));
  };

  return (
    <>
      <ChildNavigationItem
        onClick={onClick}
        title={report.name}
        icon={
          report.isShared && !report.isOwner ? (
            <Icon
              style={{ color: '#bcbcbc' }}
              name="fa-regular fa-user-group"
            />
          ) : null
        }
        actions={actions}
        isActive={isActive}
      />
      {editing && (
        <ReportContextProvider {...report}>
          <UpdateReportName
            reportId={report.id}
            onClose={() => setEditing(false)}
          />
        </ReportContextProvider>
      )}
    </>
  );
};

const DashboardSidebar = () => {
  const { can } = usePermissions();
  const { t } = useTranslation();
  const { data: reports = [], isLoading } = useReports();
  const navigate = useNavigate();
  const location = useLocation();
  const { settings } = useAppSettings();
  const hasAssets = config.enableAssetsModule;
  const navbarExtraItems = applyFilters(Filters.navbar.extraItems, null);

  const isActive = (path: string) => {
    return location.pathname.startsWith(`/assets/${path}`);
  };

  const handleSearch = React.useCallback(
    (search: string) => {
      const filters = encode({ search });
      navigate(`/assets/search?filters=${filters}`);
    },
    [navigate],
  );

  if (isLoading) {
    return null;
  }

  const sharedReports = reports.filter((item) => !item.isOwner);
  const ownReports = reports.filter((item) => item.isOwner);

  return (
    <Sidebar>
      <SidebarSearch
        isActive={isActive('search')}
        placeholder={t('report_search')}
        onChange={handleSearch}
      />
      <Navigation>
        {hasAssets && (
          <NavigationItem
            onClick={
              isActive('report')
                ? undefined
                : () => navigate(route('report.index', { id: reports[0]?.id }))
            }
            title={t('Assets Lists')}
            icon={<Icon name={'far fa-object-subtract'} />}
            isActive={isActive('report')}
          >
            <div title={t('report.shared_lists_tooltip')}>
              {sharedReports.map((report) => (
                <ReportNavItem report={report} key={report.id} />
              ))}
            </div>
            {ownReports.length > 0 && (
              <>
                <Spacer size={2} />
                <Divider variant="dashed" />
                <Spacer size={2} />
              </>
            )}
            {ownReports.map((report) => (
              <ReportNavItem report={report} key={report.id} />
            ))}
          </NavigationItem>
        )}
        {hasAssets && (
          <NavigationItem
            onClick={() => navigate('/assets/types')}
            isActive={isActive('types')}
            title={t('Assets Types')}
            icon={<Icon name={'far fa-object-intersect'} />}
          />
        )}
        {hasAssets && can('view device groups') && (
          <NavigationItem
            disabled={!settings.features.deviceGroups}
            onClick={() => navigate('/assets/groups')}
            title={t('Assets Groups')}
            isActive={isActive('groups')}
            icon={<Icon name={'far fa-object-union'} />}
          />
        )}
        {can('view service requests') && (
          <NavigationItem
            title={t('Service Tickets')}
            onClick={() => navigate('/service-tickets')}
            icon={<Icon name={'far fa-screwdriver-wrench'} />}
            isActive={location.pathname.startsWith('/service-tickets')}
          />
        )}
        {navbarExtraItems}
        {can('view system log') && (
          <NavigationItem
            title={t('Logbook')}
            onClick={() => navigate('/logs')}
            icon={<Icon name={'far fa-file-lines'} />}
          />
        )}
      </Navigation>
      <SidebarWrapper>
        <BasketButton />
      </SidebarWrapper>
      <Spacer size={2} grow />
      <SidebarNotice>
        <SystemSummary />
      </SidebarNotice>
    </Sidebar>
  );
};

export default DashboardSidebar;
