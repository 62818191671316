import React, { ComponentProps, useState } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { ModalClose } from '../../../components';
import { refetchReport } from '../hooks/use-report-spec';
import { refetchReports } from '../hooks/use-reports';
import useSaveReport, { useUpdateReport } from '../hooks/use-save-report';
import ReportContextProvider, {
  useReportContext,
} from '../context/ReportContext';
import EditIcon from '@mui/icons-material/Edit';
import { Report } from '../types';
import { useTranslation } from 'utils/translation';
import FluentForm from '../../../components/v2/Form/FluentForm';
import { text, checkbox } from '../../../components/v2/Form/factory';
import Section from 'components/v2/Section/Section';

type Values = {
  name: string;
  isShared: boolean;
  shouldOverrideAttributes: boolean;
};

type Props = {
  onSave(values: Values): Promise<void>;
};

const SaveReportForm = ({ onSave }: Props) => {
  const { t } = useTranslation();
  const { name: defaultName, isEditable, isShared } = useReportContext();

  return (
    <Section padding="small">
      <FluentForm<Values>
        action={onSave}
        defaultValues={{
          name: isEditable ? defaultName : '',
          isShared,
          shouldOverrideAttributes: true,
        }}
        fields={[
          text({
            name: 'name',
            label: t('Name'),
            rules: { required: true },
            autoFocus: true,
          }),
          checkbox({
            name: 'isShared',
            label: t('report.save.share_with_other_users'),
          }),
        ]}
      />
    </Section>
  );
};

const SaveReport = ({
  onClose,
  filters,
  active = [],
  attributes = [],
  reportId,
  hook: useSaveMutation = useSaveReport,
}: any) => {
  const [mutate] = useSaveMutation();

  const handleSave = async ({
    name,
    isShared,
    shouldOverrideAttributes,
  }: Values) => {
    await mutate({
      id: reportId,
      name,
      filters,
      active: Array.from(active).map((item: any) => item.name),
      attributes,
      isShared,
      shouldOverrideAttributes,
    });
    void refetchReports();
    void refetchReport({ id: reportId });
    onClose();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <ModalClose onClose={onClose} />
        <SaveReportForm onSave={handleSave} />
      </DialogContent>
    </Dialog>
  );
};

export const UpdateReportName = (props: {
  reportId: Report['id'];
  onClose(): void;
}) => {
  return <SaveReport {...props} hook={useUpdateReport} />;
};

const DefaultButton: React.FC<ComponentProps<typeof IconButton>> = (props) => (
  <IconButton size="small" {...props}>
    <EditIcon fontSize="inherit" />
  </IconButton>
);

export const UpdateReportNameButton: React.VFC<{
  report: Report;
  component?: React.ComponentType;
}> = ({ component: Component = DefaultButton, report }) => {
  const [active, setActive] = useState(false);

  return (
    <ReportContextProvider {...report}>
      <>
        <Component onClick={() => setActive(true)} />
        {active && (
          <UpdateReportName
            reportId={report.id}
            onClose={() => setActive(false)}
          />
        )}
      </>
    </ReportContextProvider>
  );
};

export default SaveReport;
