import { useMutation } from 'react-query';
import http from '../../../utils/http';

export function useUpdateReport() {
  return useMutation<any, any, any>(({ id, ...values }) =>
    http.put<never, any>(`/api/reports/${id}/update`, {
      ...values,
    }),
  );
}

export default function useSaveReport() {
  return useMutation<any, any, any>(
    ({
      id,
      filters,
      name,
      active,
      attributes,
      isShared,
      shouldOverrideAttributes,
    }) =>
      http.post<never, any>(`/api/reports/${id}/save`, {
        name,
        filters,
        active,
        attributes,
        isShared,
        shouldOverrideAttributes,
      }),
  );
}
