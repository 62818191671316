import { useMutation, useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { useCallback } from 'react';

export const useUserSettings = <Value = any>(
  name: string,
  fallback?: Value,
) => {
  const { data, ...query } = useQuery({
    queryKey: ['api_user_settings', name],
    queryFn: async () => {
      const result = await http.get<never, { value: Value }>(
        `/api/user-settings/${name}`,
      );

      return {
        value: result.value || fallback,
      };
    },
  });

  const mutation = useMutation<void, unknown, Record<string, any>>({
    mutationFn: (data) => http.put('/api/user-settings', data),
  });

  const update = useCallback(
    async (value: any) => {
      const result = await mutation.mutateAsync({ [name]: value });
      void query.refetch();

      return result;
    },
    [mutation.mutateAsync],
  );

  return {
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    isUpdating: mutation.isPending,
    value: data?.value || fallback,
    update,
  };
};
