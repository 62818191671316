import {
  FieldArgs,
  TextField,
  SelectField,
  PasswordField,
  EmailField,
  FieldType,
  CheckboxField,
} from './types';

export function text(args: FieldArgs<TextField>): TextField {
  return {
    ...args,
    type: FieldType.Text,
  };
}

export function password(args: FieldArgs<PasswordField>): PasswordField {
  return {
    ...args,
    type: FieldType.Password,
  };
}

export function email(args: FieldArgs<EmailField>): EmailField {
  return {
    ...args,
    type: FieldType.Email,
  };
}

export function select(args: FieldArgs<SelectField>): SelectField {
  return {
    ...args,
    type: FieldType.Select,
  };
}

export function checkbox(args: FieldArgs<CheckboxField>): CheckboxField {
  return {
    ...args,
    type: FieldType.Checkbox,
  };
}
