import Filters, { FilterType, Controller } from 'components/v2/Filters/Filters';
import Box from '@mui/material/Box';
import React, { useRef, useState } from 'react';
import { useReportContext } from '../../reports/context/ReportContext';
import { useReportTotalResults } from '../../reports/hooks/use-report';
import { useFiltersContext } from '../../../components/v2/Filters/FiltersContext';
import SaveReport from '../../reports/components/SaveReport';
import { useTableContext } from '../../../components/v2/Table/TableContextProvider';

const ReportFilters = () => {
  const { params, id } = useReportContext();
  const filters = useFiltersContext();
  const { attributes } = useTableContext();
  const [saving, setSaving] = useState(false);
  const filtersRef = useRef<Controller>();

  const { data, isLoading, isFetching } = useReportTotalResults({
    id,
    params: filters.values,
  });

  return (
    <Box>
      <Filters
        items={params.map((item) => ({
          ...item,
          type: item.type as FilterType,
        }))}
        isLoading={isLoading || isFetching}
        totalCount={data?.total}
        onSubmit={filters.commitValues}
        onSave={() => setSaving(true)}
        controllerRef={filtersRef}
      />
      {saving && (
        <SaveReport
          reportId={id}
          filters={filters.values}
          active={filtersRef.current?.getActiveFilters()}
          attributes={attributes}
          onClose={() => setSaving(false)}
        />
      )}
    </Box>
  );
};

export default ReportFilters;
