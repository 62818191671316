import React, { useEffect } from 'react';
import {
  getCoreRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import { RowData } from '@tanstack/table-core';
import DefaultTable from './DefaultTable';
import { DataTableProps } from './types';

function DataTable<TData extends RowData>({
  data,
  columns,
  pagination,
  actions,
  options,
  onSelectionChange,
  ...rest
}: DataTableProps<TData>) {
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: options?.getRowId,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  useEffect(() => {
    onSelectionChange?.(
      table.getSelectedRowModel().rows.map((row) => row.original),
    );
  }, [rowSelection]);

  return (
    <DefaultTable
      {...rest}
      table={table}
      actions={actions}
      pagination={pagination}
    />
  );
}

export default DataTable;
