import React from 'react';
import { DefaultField } from './DefaultField';
import { FieldProps } from './types';
import { DateField } from './DateField';
import { SliderField } from './Slider';
import { CheckboxField } from './CheckboxField';
import { LongTextField } from './LongTextField';
import { SelectField } from './SelectField';
import { SelectCreatable } from './SelectCreatable';
import { RadioGroupField } from './RadioGroupField';
import { AutocompleteField } from './AutocompleteField';
import { FileField } from './FileField';
import { applyFilters, Filters } from '../../../../core';
import Editor from './Editor';

type FieldsMap = {
  [key: string]: any;
};

const fieldsMap: FieldsMap = {
  'radio-group': RadioGroupField,
  select: SelectField,
  'select-creatable': SelectCreatable,
  autocomplete: AutocompleteField,
  longtext: LongTextField,
  boolean: CheckboxField,
  range: SliderField,
  date: DateField,
  file: FileField,
  editor: Editor,
};

const FormField: React.FC<FieldProps> = (props) => {
  const FieldComponent = React.useMemo(() => {
    let Component = DefaultField;

    if (props.type in fieldsMap) {
      Component = fieldsMap[props.type] as any;
    }

    return applyFilters(Filters.intelligenceLayer.fieldComponent, Component, {
      props,
    });
    // eslint-disable-next-line
  }, [props.type, props.fieldName]);

  return <FieldComponent {...props} />;
};

export { FormField };
