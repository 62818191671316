import React, { useState } from 'react';
import { IconButton, Dialog, DialogContent, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ModalClose } from '../../../components';
import TransferList from '../../../components/v2/TransferList/TransferList';

type Props = {
  attributes: {
    [key: string]: string;
  };
  enabledAttributes: string[];
  onChange(attributes: string[]): void;
  renderButton?(props: { onClick(): void }): React.ReactNode;
};

const defaultRenderButton: Props['renderButton'] = (props) => (
  <IconButton {...props} size="large">
    <MoreVertIcon />
  </IconButton>
);

const AttributesManager: React.FC<Props> = ({
  attributes: defaultAttributes,
  enabledAttributes = [],
  onChange,
  renderButton = defaultRenderButton,
}) => {
  const [active, setActive] = useState(false);
  const availableAttributes = Object.keys(defaultAttributes);

  const mapTransferItem = (item: string) => ({
    id: item,
    label: defaultAttributes[item],
  });

  const selectedAttributes = enabledAttributes.filter((i) =>
    availableAttributes.includes(i),
  );

  return (
    <Box sx={{ textAlign: 'right' }}>
      {renderButton({ onClick: () => setActive(true) })}
      {active && (
        <Dialog open maxWidth="md" fullWidth onClose={() => setActive(false)}>
          <ModalClose onClose={() => setActive(false)} />
          <DialogContent sx={{ pt: 8, pb: 2 }}>
            <TransferList
              available={availableAttributes.map(mapTransferItem)}
              selected={selectedAttributes.map(mapTransferItem)}
              onSubmit={(items) => {
                const nextAttributes = items
                  .map((item) => item.id)
                  .filter((i) => availableAttributes.includes(i));

                onChange(nextAttributes);
                setActive(false);
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default AttributesManager;
